import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { supportedLanguages } from "../i18n";
import {
  Avatar,
  Badge,
  Button,
  ConfigProvider,
  Divider,
  Flex,
  Popover,
  Space,
} from "antd";
import de from "antd/lib/locale/de_DE";
import en from "antd/lib/locale/en_US";
import pl from "antd/lib/locale/pl_PL";
import ua from "antd/lib/locale/uk_UA";
import Layout, { Header } from "antd/lib/layout/layout";
import Language from "../components/Language";
import "./MainLayout.css";
import { navigatePath } from "../store/utils/dasboard/navigate";
import { usePreferences } from "../store/globalStore";
import {
  BankOutlined,
  BellOutlined,
  ExportOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

export const MainLayout = () => {
  const { i18n } = useTranslation();
  // const { lang } = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation(["dashboard"]);
  const { category, language } = usePreferences();

  useEffect(() => {
    const lang = supportedLanguages.includes(language.value)
      ? language.value
      : "en";
    i18n.changeLanguage(lang);
    navigate(navigatePath(language.value, lang, category));
  }, [language, i18n, navigate]);

  const locale = () => {
    switch (language) {
      case "de":
        return de;
      case "en":
        return en;
      case "pl":
        return pl;
      case "ua":
        return ua;
      default:
        return en;
    }
  };

  return (
    <ConfigProvider locale={locale()}>
      <Layout style={{ height: "100vh", backgroundColor: "#fff" }}>
        <Header
          style={{
            display: "flex",
            alignItems: "space-between",
            backgroundColor: "#fff",
            width: "100%",
          }}
        >
          <Space style={{ justifyContent: "flex-end", flexGrow: "1" }}>
            <Language />
            <Popover
              placement="bottomRight"
              content={
                <Flex gap="middle" vertical>
                  <div>Notification 1</div>
                  <div>Notification 2</div>
                  <div>Notification 3</div>
                  <div>Notification 4</div>
                  <div>Notification 5</div>
                </Flex>
              }
            >
              <Badge count={5}>
                <Avatar icon={<BellOutlined />} />
              </Badge>
            </Popover>
            <Popover
              placement="bottomRight"
              content={
                <Flex gap="middle" vertical>
                  <Space>
                    <Avatar
                      icon={<UserOutlined />}
                      style={{ backgroundColor: "green" }}
                    />
                    <div>
                      <div>Joe Doe</div>
                      <div>joe.doe@example.com</div>
                    </div>
                  </Space>
                  <Button
                    type="link"
                    icon={<UserOutlined />}
                    style={{ justifyContent: "left" }}
                  >
                    My account settings
                  </Button>
                  <Button
                    type="link"
                    icon={<TeamOutlined />}
                    style={{ justifyContent: "left" }}
                  >
                    Join a team
                  </Button>
                  <Divider style={{ margin: 0 }} />
                  <Button
                    type="link"
                    icon={<InfoCircleOutlined />}
                    style={{ justifyContent: "left" }}
                  >
                    <Space
                      style={{ width: "100%", justifyContent: "space-between" }}
                    >
                      Help Center
                      <ExportOutlined />
                    </Space>
                  </Button>
                  <Button
                    type="link"
                    icon={<InfoCircleOutlined />}
                    style={{ justifyContent: "left" }}
                  >
                    <Space
                      style={{ width: "100%", justifyContent: "space-between" }}
                    >
                      Academy
                      <BankOutlined />
                    </Space>
                  </Button>
                  <Divider style={{ margin: 0 }} />
                  <Button
                    type="link"
                    icon={<LogoutOutlined />}
                    style={{ justifyContent: "left" }}
                  >
                    Logout
                  </Button>
                </Flex>
              }
            >
              <Avatar icon={<UserOutlined />} />
            </Popover>
          </Space>
        </Header>
        <Outlet />
      </Layout>
    </ConfigProvider>
  );
};
