export const dashboardActions = {
  TABLE: "table",
  SPECS: "specs",
};

export const dashboardInitialState = {
  table: { source: [], columns: [] },
  specs: [],
};

export const dashboardReducer = (state, action) => {
  switch (action.type) {
    case dashboardActions.TABLE:
      return {
        ...state,
        table: action.table,
      };
    case dashboardActions.SPECS:
      return {
        ...state,
        specs: action.specs,
      };
    default:
      return state;
  }
};
