import { Divider, Dropdown, Form, notification, Space, TreeSelect } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { usePreferences } from "../../../../../../store/globalStore";
import { TextParameter } from "./components/TextParameter";
import { NumberParameter } from "./components/NumberParameter";
import { LogicalParameter } from "./components/LogicalParameter";
import { fetchUnits } from "./api/units";
import { useParams } from "react-router-dom";
import { error, success } from "../../../../../../common/message/notification";
import {
  deleteParameter,
  fetchParameter,
  persistParameter,
} from "./api/parameter";
import { fetchGroupParameters } from "./api/groupParameter";

export default function Parameter() {
  const TEXT = "TEXT";
  const NUMBER = "NUMBER";
  const LOGICAL = "LOGICAL";

  const { language } = usePreferences();
  const { lang } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [t] = useTranslation("catman");

  const [input, setInput] = useState({
    groupParameters: [],
    groupParameterId: null,
    units: [],
    addNew: TEXT,
    parameters: [],
  });

  useEffect(() => {
    const initInput = async () => {
      const groupParameters = (await fetchGroupParameters(lang)) || [];

      setInput({
        ...input,
        groupParameters: groupParameters.map((groupParameter, i) => ({
          ...groupParameter,
          unitId: groupParameter?.unit_id?.split("|"),
        })),
        units: (await fetchUnits(lang)) || [],
      });
    };
    initInput();
  }, []);

  const filterTreeNode = (input, node) => {
    return node?.title?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
  };

  const groupParameterOnChange = async (value) => {
    if (!value) {
      setInput({
        ...input,
        groupParameterId: null,
        parameters: [],
      });
      return;
    }

    const parameters = (await fetchParameter(value?.split("|")[1])) || [];

    setInput({
      ...input,
      groupParameterId: value,
      parameters: parameters.map((parameter, i) => ({
        ...parameter,
        language: lang,
        key: i + 1,
      })),
    });
  };

  const newParameterTypeOnSelect = ({ item: { props } }) => {
    setInput({
      ...input,
      addNew: props.value,
    });
  };

  const addNewParameterOnClick = () => {
    setInput({
      ...input,
      parameters: [
        ...input.parameters,
        {
          key: Math.max(...input.parameters.map((item) => item.key), 0) + 1,
          id: null,
          order_index: null,
          type: input.addNew,
          language: language.value,
          translation: {},
        },
      ],
    });
  };

  const parameterOnUpdate = async (item) => {
    try {
      await persistParameter({
        group_parameter_id: input.groupParameterId?.split("|")[1],
        id: item.value?.split("|")[1],
        type: item.type,
        order_index: item.order_index,
        unit_id: item.unitId?.join("|"),
        min_value: item.min_value,
        max_value: item.max_value,
        translation: item.translation,
      });
      success(api, t("success"), t("persistParameter"));
      setInput({
        ...input,
        parameters: input.parameters.map((parameter) =>
          parameter.key === item.key ? item : parameter,
        ),
      });
    } catch (e) {
      error(api, t("error"), t("persistParameter"));
      console.log("Persist", e.message);
    }
  };

  const parameterOnRemove = async (item) => {
    if (item.value !== null) {
      try {
        await deleteParameter(item.value.split("|")[1]);
        success(api, t("success"), t("deleteParameter"));
      } catch (e) {
        error(api, t("error"), t("persistParameter"));
        console.log("Delete", e.message);
      }
    }
    setInput({
      ...input,
      parameters: input.parameters.filter(
        (parameter) => parameter.key !== item.key,
      ),
    });
  };

  return (
    <Form layout="vertical">
      {contextHolder}
      <Form.Item label={t("parentGroupParameters")}>
        <Space.Compact style={{ width: "100%" }}>
          <TreeSelect
            treeData={input.groupParameters}
            value={input.groupParameterId}
            onChange={groupParameterOnChange}
            filterTreeNode={filterTreeNode}
            placeholder={t("parentGroupParameters")}
            showSearch
            allowClear={{
              clearIcon: <CloseOutlined onClick={groupParameterOnChange} />,
            }}
            treeDefaultExpandAll
            style={{
              width: "100%",
            }}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
            }}
          />
        </Space.Compact>
      </Form.Item>
      {input.parameters?.map((item) => (
        <div key={item.key}>
          {item.type === TEXT && (
            <TextParameter
              lang={language}
              item={item}
              onUpdate={parameterOnUpdate}
              onRemove={parameterOnRemove}
            />
          )}
          {item.type === NUMBER && (
            <NumberParameter
              lang={language}
              units={input.units}
              item={item}
              onUpdate={parameterOnUpdate}
              onRemove={parameterOnRemove}
            />
          )}
          {item.type === LOGICAL && (
            <LogicalParameter
              lang={language}
              item={item}
              onUpdate={parameterOnUpdate}
              onRemove={parameterOnRemove}
            />
          )}
        </div>
      ))}
      <Divider />
      {input.groupParameterId !== null && (
        <Form.Item>
          <Space.Compact>
            <Dropdown.Button
              menu={{
                items: [
                  {
                    value: TEXT,
                    label: t("textParameter"),
                  },
                  {
                    value: NUMBER,
                    label: t("numberParameter"),
                  },
                  {
                    value: LOGICAL,
                    label: t("logicalParameter"),
                  },
                ],
                onClick: newParameterTypeOnSelect,
              }}
              onClick={addNewParameterOnClick}
            >
              <PlusOutlined /> {t("addNew")}:{" "}
              {t(`${input?.addNew?.toLowerCase()}Parameter`)}
            </Dropdown.Button>
          </Space.Compact>
        </Form.Item>
      )}
    </Form>
  );
}
