import {
  Button,
  Cascader,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
} from "antd";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const NumberParameter = ({ units, item, lang, onUpdate, onRemove }) => {
  const [t] = useTranslation("catman");

  const [input, setInput] = useState(item);

  const languageOnSelect = (value) => {
    setInput({ ...input, language: value });
  };

  const nameOnInput = ({ target: { value } }) => {
    setInput({
      ...input,
      translation: { ...input.translation, [input.language]: value },
    });
  };

  const minValueOnChange = (value) => {
    setInput({ ...input, min_value: value });
  };

  const maxValueOnChange = (value) => {
    setInput({ ...input, max_value: value });
  };

  const unitOnChange = (value) => {
    setInput({
      ...input,
      unitId: value,
    });
  };

  const onSave = () => {
    onUpdate(input);
  };

  const onDelete = () => {
    onRemove(item);
  };

  return (
    <>
      <Divider orientation="left" plain>
        {t(`numberParameter`)}
      </Divider>
      <Form.Item label={t("parameterName")}>
        <Space.Compact style={{ width: "100%" }}>
          <Select
            value={input.language}
            onChange={languageOnSelect}
            options={[
              {
                value: "de",
                label: "🇩🇪",
              },
              {
                value: "en",
                label: "🇺🇸",
              },
              {
                value: "pl",
                label: "🇵🇱",
              },
              {
                value: "ua",
                label: "🇺🇦",
              },
            ]}
            style={{ width: "auto" }}
            virtual
          />
          <Input
            value={input.translation[input.language]}
            onChange={nameOnInput}
            placeholder={t("parameterName")}
            style={{ width: "100%" }}
          />
          <Button icon={<DeleteOutlined />} danger onClick={onDelete} />
          <Button icon={<SaveOutlined />} type="primary" onClick={onSave} />
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("measurementUnit")}>
        <Space.Compact style={{ width: "100%" }}>
          <Cascader
            expandTrigger="hover"
            options={units}
            onChange={unitOnChange}
            placeholder={t("measurementUnit")}
            showSearch={{
              filter: (inputValue, path) =>
                path.some(
                  (option) =>
                    option.label
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) > -1,
                ),
            }}
            onSearch={(value) => console.log(value)}
          />
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("validValueForParameter")}>
        <Space.Compact style={{ width: "100%" }}>
          <InputNumber
            value={input.min_value}
            onChange={minValueOnChange}
            defaultValue={0}
            min={0}
            keyboard
            changeOnWheel
          />
          <InputNumber
            value={input.max_value}
            onChange={maxValueOnChange}
            defaultValue={1}
            min={0}
            keyboard
            changeOnWheel
          />
        </Space.Compact>
      </Form.Item>
    </>
  );
};
