const showNotification = (api, type, message, description) => {
  api[type]({
    message,
    description,
    showProgress: true,
    pauseOnHover: true
  });
}

export const success = (api, message, description) => {
  showNotification(api, "success", message, description);
}

export const error = (api, message, description) => {
  showNotification(api, "error", message, description);
}
