import { Breadcrumb, Tabs } from "antd";
import { useEffect, useState } from "react";
import "./Category.css";
import NavigationSection from "./components/navigationSection/NavigationSection";
import ProductCategorySection from "./components/productCategorySection/ProductCategorySection";
import { GroupParameters } from "./components/groupParameters/GroupParameters";
import { useTranslation } from "react-i18next";
import CategoryManagement from "./components/categoryManagement/CategoryManagement";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Parameter from "./components/parameter/Parameter";
import { ParameterOption } from "./components/paremeterOption/ParameterOption";

export default function Category() {
  const [t] = useTranslation("catman");

  const { navigation } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [input, setInput] = useState({
    navigation: navigation || "navigation",
  });

  useEffect(() => {
    setInput({
      ...input,
      navigation: navigation || "navigation",
    });
  }, [navigation]);

  const navigationOnChange = (key) => {
    setInput({ ...input, navigation: key });
    navigate(`${location.pathname.split("/").slice(0, -1).join("/")}/${key}`);
  };

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: t("catalog"),
          },
          {
            title: t("category"),
          },
        ]}
      />
      <Tabs
        activeKey={input.navigation}
        onChange={navigationOnChange}
        items={[
          {
            key: "navigation",
            label: t("navigationSection"),
            children: <NavigationSection />,
          },
          {
            key: "category",
            label: t("productCategory"),
            children: <ProductCategorySection />,
          },
          {
            key: "group-parameters",
            label: t("groupParameters"),
            children: <GroupParameters />,
          },
          {
            key: "parameters",
            label: t("parameters"),
            children: <Parameter />,
          },
          {
            key: "parameter-options",
            label: t("parameterOptions"),
            children: <ParameterOption />,
          },
          {
            key: "category-management",
            label: t("categoryManagement"),
            children: <CategoryManagement />,
          },
        ]}
      />
    </>
  );
}
