import {
  deleteAPI,
  getAPI,
  postAPI,
} from "../../../../../../../common/api/api";

const PATH = "catman/navigation";

export const fetchNavigations = async (lang) => getAPI(PATH, { Locale: lang });

export const fetchNavigation = async (id) => getAPI(`${PATH}/${id}`);

export const persistNavigation = async (body) => postAPI(PATH, body);

export const deleteNavigation = async (id) => deleteAPI(`${PATH}/${id}`);
