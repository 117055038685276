import { createBrowserRouter, Navigate } from "react-router-dom";
import { MainLayout } from "../layout/MainLayout";
import Home from "../pages/home/Home";
import { i18nInit } from "../i18n";
import Catman from "../pages/catman/Catman";

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={`/${i18nInit.resolvedLanguage || "en"}`} replace />,
  },
  {
    path: "/:lang",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "catman",
        element: <Navigate to="catalog/navigation" replace />,
      },
      {
        path: "catman/catalog/:navigation",
        element: <Catman />,
        children: [
          {
            index: true,
            element: <Navigate to="navigation" replace />,
          },
        ],
      },
      // {
      //   path: "catman",
      //   element: <Navigate to="catalog/navigation" replace />,
      //   children: [
      //     {
      //       path: "catalog",
      //       element: <Navigate to="navigation" replace />,
      //       children: [
      //         {
      //           path: "navigation",
      //           element: <Catman />,
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        path: "*",
        element: <Home />,
      },
    ],
  },
]);

export default AppRouter;
