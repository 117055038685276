import { Button, Divider, Form, Input, Select, Space } from "antd";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const LogicalParameter = ({ item, lang, onUpdate, onRemove }) => {
  const [t] = useTranslation("catman");

  const [input, setInput] = useState(item);

  const languageOnSelect = (value) => {
    setInput({ ...input, language: value });
  };

  const nameOnInput = ({ target: { value } }) => {
    setInput({
      ...input,
      translation: { ...input.translation, [input.language]: value },
    });
  };

  const onSave = () => {
    onUpdate(input);
  };

  const onDelete = () => {
    onRemove(item);
  };

  return (
    <>
      <Divider orientation="left" plain>
        {t(`logicalParameter`)}
      </Divider>
      <Form.Item label={t("parameterName")}>
        <Space.Compact style={{ width: "100%" }}>
          <Select
            value={input.language}
            onChange={languageOnSelect}
            options={[
              {
                value: "de",
                label: "🇩🇪",
              },
              {
                value: "en",
                label: "🇺🇸",
              },
              {
                value: "pl",
                label: "🇵🇱",
              },
              {
                value: "ua",
                label: "🇺🇦",
              },
            ]}
            style={{ width: "auto" }}
            virtual
          />
          <Input
            value={input.translation[input.language]}
            onChange={nameOnInput}
            placeholder={t("parameterName")}
            style={{ width: "100%" }}
          />
          <Button icon={<DeleteOutlined />} danger onClick={onDelete} />
          <Button icon={<SaveOutlined />} type="primary" onClick={onSave} />
        </Space.Compact>
      </Form.Item>
    </>
  );
};
