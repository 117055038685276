import React, { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { DevSupport } from "@react-buddy/ide-toolbox";
import { ComponentPreviews, useInitial } from "./dev";
import { RouterProvider } from "react-router-dom";
import AppRouter from "./routes/AppRouter";
import { StoreProvider } from "./store/globalStore";
import { I18nextProvider } from "react-i18next";
import { i18nInit } from "./i18n";

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <DevSupport
      ComponentPreviews={ComponentPreviews}
      useInitialHook={useInitial}
    >
      <StoreProvider>
        <Suspense fallback="loading">
          <I18nextProvider i18n={i18nInit}>
            <RouterProvider router={AppRouter} />
          </I18nextProvider>
        </Suspense>
      </StoreProvider>
    </DevSupport>
  </StrictMode>,
);

reportWebVitals();
