import Layout, { Content } from "antd/lib/layout/layout";
import { Button, Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingOutlined,
  TagOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import Category from "./components/category/Category";
import Products from "./components/products/Products";
import Settings from "./components/settings/Settings";
import { useTranslation } from "react-i18next";

export default function Catman() {
  const [t] = useTranslation("catman");

  const [input, setInput] = useState({
    collapsed: true,
    navigation: "category",
    navigationKeys: ["catalog", "category"],
  });

  const onNavigationKeysChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => input.navigationKeys.indexOf(key) === -1,
    );
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex(
          (key) =>
            input.navigationKeys[key] === input.navigationKeys[currentOpenKey],
        );
      setInput({
        ...input,
        navigationKeys: openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter(
            (key) =>
              input.navigationKeys[key] <= input.navigationKeys[currentOpenKey],
          ),
      });
    } else {
      setInput({
        ...input,
        navigationKeys: openKeys,
      });
    }
  };

  return (
    <>
      <Layout>
        <Sider
          trigger={null}
          collapsedWidth={0}
          collapsible
          theme="light"
          collapsed={input.collapsed}
        >
          <div className="demo-logo-vertical" />
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={input.navigation}
            onSelect={({ key }) => setInput({ ...input, navigation: key })}
            openKeys={input.navigationKeys}
            onOpenChange={onNavigationKeysChange}
            items={[
              {
                key: "catalog",
                icon: <ShopOutlined />,
                label: t("catalog"),
                children: [
                  {
                    key: "category",
                    icon: <TagOutlined />,
                    label: t("category"),
                  },
                  {
                    key: "product",
                    icon: <ShoppingOutlined />,
                    label: t("product"),
                  },
                ],
              },
              // {
              //   key: "order",
              //   icon: <ShoppingCartOutlined/>,
              //   label: t("order"),
              // },
              {
                key: "settings",
                icon: <SettingOutlined />,
                label: t("settings"),
              },
            ]}
          />
        </Sider>
        <Layout style={{ padding: "0 50px" }}>
          <Button
            type="text"
            icon={
              input.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
            }
            onClick={() => setInput({ ...input, collapsed: !input.collapsed })}
            style={{
              justifyContent: "start",
            }}
          />
          <Content>
            {input.navigation === "category" && <Category />}
            {input.navigation === "products" && <Products />}
            {/*{navigation.menu === "orders" && <Orders/>}*/}
            {input.navigation === "settings" && <Settings />}
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
