import {
  deleteAPI,
  getAPI,
  postAPI,
} from "../../../../../../../common/api/api";

const PATH = "catman/navigation/category/group-parameter";

export const fetchGroupParameters = async (id) => getAPI(`${PATH}/${id}`);

export const persistGroupParameter = async (body) => postAPI(PATH, body);

export const deleteGroupParameter = async (id) => deleteAPI(`${PATH}/${id}`);
