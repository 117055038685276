import { combineReducers } from "./utils/combineReducers";
import { preferencesInitialState, preferencesReducer } from "./preferences";
import { dashboardInitialState, dashboardReducer } from "./dashboard";

export const rootReducer = combineReducers({
  preferences: preferencesReducer,
  dashboard: dashboardReducer,
});

export const rootInitialState = {
  preferences: preferencesInitialState,
  dashboard: dashboardInitialState,
};
