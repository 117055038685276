import {
  deleteAPI,
  getAPI,
  postAPI,
} from "../../../../../../../common/api/api";

const PATH = "catman/navigation/category/group-parameter/parameter/option";

export const fetchParameterOptions = async (lang) =>
  getAPI(PATH, { Locale: lang });

export const fetchParameterOption = async (id) => getAPI(`${PATH}/${id}`);

export const persistParameterOption = async (body) => postAPI(PATH, body);

export const deleteParameterOption = async (id) => deleteAPI(`${PATH}/${id}`);
