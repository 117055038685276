export const preferencesActions = {
  CATEGORY: "category",
  CATEGORIES: "categories",
  LANGUAGE: "language",
};

export const preferencesInitialState = {
  category: [],
  categories: [],
  language: {
    value: "en",
    label: "🇺🇸",
  },
};

const lang = (language) => {
  switch (language) {
    case "de":
      return "🇩🇪";
    case "en":
      return "🇺🇸";
    case "pl":
      return "🇵🇱";
    case "ua":
      return "🇺🇦";
    default:
      return "🇺🇸";
  }
};

export const preferencesReducer = (state, action) => {
  switch (action.type) {
    case preferencesActions.CATEGORY:
      return {
        ...state,
        category: action.category,
      };
    case preferencesActions.CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case preferencesActions.LANGUAGE:
      return {
        ...state,
        language: {
          value: action.language,
          label: lang(action.language),
        },
      };
    default:
      return state;
  }
};
