export const navigatePath = (current_lang, new_lang, current_cat) => {
  if (
    current_lang &&
    current_cat &&
    window.location.pathname.includes("category")
  ) {
    return window.location.pathname.replace(
      `/${current_lang}/category/${current_cat}`,
      `/${new_lang}/category/${current_cat}`,
    );
  }

  return window.location.pathname.replace(`/${current_lang}`, `/${new_lang}`);
};
