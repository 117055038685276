import {Select} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {usePreferences} from "../store/globalStore";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

export default function Language() {
  const {i18n} = useTranslation();
  const {lang} = useParams();
  const navigate = useNavigate();
  const {language, setLanguage} = usePreferences();

  useEffect(() => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  }, []);

  const updateLanguage = (language) => {
    navigate(window.location.pathname.replace(`/${lang}`, `/${language}`));
    setLanguage(language);
    i18n.changeLanguage(language);
  };

  return (
      <Select
          value={language.value}
          options={[
            {
              value: "de",
              label: "🇩🇪",
              title: "Deutsch",
            },
            {
              value: "en",
              label: "🇺🇸",
              title: "English",
            },
            {
              value: "pl",
              label: "🇵🇱",
              title: "Polski",
            },
            {
              value: "ua",
              label: "🇺🇦",
              title: "Українська",
            },
          ]}
          onChange={updateLanguage}
      />
  );
}
