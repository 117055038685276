import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout, { Content } from "antd/lib/layout/layout";
import Dropdown from "antd/lib/dropdown/dropdown";
import { Button, Space, Table } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { Card, Checkbox } from "antd/lib";
import { usePreferences } from "../../store/globalStore";
import "./Home.css";
import { useParams } from "react-router-dom";

export default function Home() {
  const [t] = useTranslation(["dashboard"]);
  const { lang } = useParams();
  const {
    table,
    setTable,
    specs,
    setSpecs,
    category,
    setCategory,
    categories,
    setCategories,
  } = usePreferences();

  useEffect(() => {
    const f = async () => {
      const data = await fetch(`https://requrix.com/api/navigation`, {
        method: "GET",
        redirect: "follow",
        headers: {
          "Accept-Language": "en",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((res) => {
        return res.json();
      });

      console.log(data);
    };

    f();
  }, []);

  const [dashboard, setDashboard] = useState({
    // sidebar: { collapsed: false },
    panel: { activeTab: "products" },
    filter: { active: false, items: [] },
  });

  const hideColumns = (columns) => {
    setTable({
      ...table,
      columns: table.columns.map((column) => ({
        ...column,
        hidden: columns === undefined ? false : !columns.includes(column.key),
      })),
    });

    setDashboard({
      ...dashboard,
      filter: {
        ...dashboard.filter,
        items:
          columns === undefined
            ? table.columns?.map((col) => col.key)
            : columns,
      },
    });
  };

  const handleOpenChange = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setDashboard({
        ...dashboard,
        filter: {
          ...dashboard.filter,
          active: nextOpen,
          items:
            dashboard.filter.items.length === 0
              ? table.columns?.map((col) => col.key)
              : dashboard.filter.items,
        },
      });
    }
  };

  return (
    <>
      {table?.columns?.length > 0 ? (
        <Content style={{ overflow: "auto" }}>
          <Layout>
            {/*<Sider*/}
            {/*  collapsible*/}
            {/*  trigger={null}*/}
            {/*  breakpoint="lg"*/}
            {/*  collapsedWidth={0}*/}
            {/*  collapsed={dashboard.sidebar.collapsed}*/}
            {/*  onCollapse={(value) =>*/}
            {/*    setDashboard({ ...dashboard, sidebar: { collapsed: value } })*/}
            {/*  }*/}
            {/*  width={300}*/}
            {/*  style={{*/}
            {/*    background: "#fff",*/}
            {/*    padding: dashboard.sidebar.collapsed ? "0" : "0 1em",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {!dashboard.sidebar.collapsed && (*/}
            {/*    <Dropdown menu={{ items: [] }} placement="bottomLeft">*/}
            {/*      <Button*/}
            {/*        type="text"*/}
            {/*        icon={<DownOutlined />}*/}
            {/*        iconPosition="end"*/}
            {/*        style={{*/}
            {/*          width: "100%",*/}
            {/*          padding: dashboard.sidebar.collapsed ? "0" : "8px 12px",*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        {!dashboard.sidebar.collapsed && "Test"}*/}
            {/*      </Button>*/}
            {/*    </Dropdown>*/}
            {/*  )}*/}
            {/*</Sider>*/}
            <Layout>
              <Card
                bordered={false}
                size="small"
                tabList={[
                  {
                    key: "products",
                    tab: t("products"),
                  },
                  {
                    key: "characteristics",
                    tab: t("characteristics"),
                  },
                  {
                    key: "additional_info",
                    tab: t("additional_info"),
                  },
                ]}
                activeTabKey={dashboard.panel.activeTab}
                onTabChange={(key) =>
                  setDashboard({ ...dashboard, panel: { activeTab: key } })
                }
                style={{
                  padding: "0 40px",
                }}
              >
                {dashboard.panel.activeTab === "products" && (
                  <>
                    <Space
                      style={{
                        paddingBottom: "10px",
                        width: "100%",
                        justifyContent: "flex-end",
                        // justifyContent: "space-between",
                      }}
                    >
                      {/*<Button*/}
                      {/*  icon={*/}
                      {/*    dashboard.sidebar.collapsed ? (*/}
                      {/*      <MenuUnfoldOutlined />*/}
                      {/*    ) : (*/}
                      {/*      <MenuFoldOutlined />*/}
                      {/*    )*/}
                      {/*  }*/}
                      {/*  onClick={() =>*/}
                      {/*    setDashboard({*/}
                      {/*      ...dashboard,*/}
                      {/*      sidebar: { collapsed: !dashboard.sidebar.collapsed },*/}
                      {/*    })*/}
                      {/*  }*/}
                      {/*  style={{*/}
                      {/*    width: 45,*/}
                      {/*    height: 32,*/}
                      {/*  }}*/}
                      {/*/>*/}
                      <Space align="end">
                        <Dropdown
                          onOpenChange={handleOpenChange}
                          open={dashboard.filter.active}
                          menu={{
                            items: [
                              {
                                key: "itemGroup",
                                label: (
                                  <div
                                    style={{
                                      maxHeight: 200,
                                      overflowY: "auto",
                                    }}
                                  >
                                    <Checkbox.Group
                                      value={dashboard.filter.items}
                                      onChange={(val) => hideColumns(val)}
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {table.columns.map((item) => (
                                        <Checkbox
                                          key={item.key}
                                          value={item.key}
                                        >
                                          {item.title?.toString()}
                                        </Checkbox>
                                      ))}
                                    </Checkbox.Group>
                                  </div>
                                ),
                              },
                              { type: "divider" },
                              {
                                key: "select_all",
                                label: t("select_all"),
                                onClick: () => hideColumns(),
                              },
                            ],
                          }}
                          trigger={["click"]}
                        >
                          <Button icon={<FilterOutlined />} />
                        </Dropdown>
                      </Space>
                    </Space>

                    <Table
                      size="middle"
                      columns={table.columns}
                      dataSource={table.source}
                      scroll={{ x: "max-content", y: 300 }}
                      pagination={{
                        pageSize: 10,
                      }}
                      className={"table-striped-rows"}
                    />
                  </>
                )}
              </Card>
            </Layout>
          </Layout>
        </Content>
      ) : null}
    </>
  );
}
