const api = async (method, path, headers, body) => {
  const res = await fetch(`https://requrix.com/api/${path}`, {
    method: method,
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...headers,
    },
    body: body !== undefined ? JSON.stringify(body) : null,
  });

  return res.ok ? (res.status !== 204 ? await res.json() : null) : null;
};

export const getAPI = async (path, headers) => {
  return api("GET", path, headers);
};

export const postAPI = async (path, body) => {
  return api("POST", path, null, body);
};

export const deleteAPI = async (path, body) => {
  return api("DELETE", path, null, body);
};
