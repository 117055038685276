import React, { createContext, useContext, useReducer } from "react";
import { rootInitialState, rootReducer } from "./reducers/root";
import { preferencesActions } from "./reducers/preferences";
import { dashboardActions } from "./reducers/dashboard";

const StoreContext = createContext({
  state: {},
  dispatch: () => {},
});

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    rootReducer,
    null,
    () => rootInitialState,
  );

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);

export const usePreferences = () => {
  const { state, dispatch } = useStore();

  return {
    language: state.preferences.language,
    setLanguage: (language) =>
      dispatch({ type: preferencesActions.LANGUAGE, language }),
    category: state.preferences.category,
    setCategory: (category) =>
      dispatch({ type: preferencesActions.CATEGORY, category }),
    categories: state.preferences.categories,
    setCategories: (categories) =>
      dispatch({ type: preferencesActions.CATEGORIES, categories }),
    table: state.dashboard.table,
    setTable: (table) => dispatch({ type: dashboardActions.TABLE, table }),
    specs: state.dashboard.specs,
    setSpecs: (specs) => dispatch({ type: dashboardActions.SPECS, specs }),
  };
};
