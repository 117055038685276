import {
  deleteAPI,
  getAPI,
  postAPI,
} from "../../../../../../../common/api/api";

const PATH = "catman/navigation/category";

export const fetchCategories = async (lang) => getAPI(PATH, { Locale: lang });

export const fetchCategory = async (id) => getAPI(`${PATH}/${id}`);

export const persistCategory = async (body) => postAPI(PATH, body);

export const deleteCategory = async (id) => deleteAPI(`${PATH}/${id}`);
